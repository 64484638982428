<template>
  <div class="index">
    <!-- 曼扉心理 -->
    <div class="banner">
      <img v-if="!$store.state.isPhone" :src="$store.state.bannerUrl + $store.state.bannerImgs.news" alt="">
      <img v-else src="../../assets/images/banner/新闻banner备份 4.jpg" alt=""/>
      <!-- <img :src="$store.state.bannerUrl + $store.state.bannerImgs.news" alt=""> -->
    </div>
    <div :class="isPc === 'true' ? 'main' : 'phoneStyle main'">
      <div class="titleContent">
        <p class="p1">曼扉心理</p>
        <span class="span1">BRIEF INTRODUCTION</span>
      </div>
      <div class="newsContent">
        <div class="newsList">
          <div class="item" v-for="item in newsList" :key="item.id" @click="toDetail(item.id)">
            <div class="left">
              <div class="box1"></div>
              <div class="box2"></div>
              <img v-if="item.thumbnail" :src="$store.state.imgUrl + item.thumbnail" alt="">
              <img v-else src="../../assets/images/news/编组 33备份 12@2x.png" alt="">
            </div>
            <div class="right">
              <div class="title">
                <!-- <span class="span1">标题：</span> -->
                <p class="p4"><span class="isHot" v-if="item.withHot">最热</span>
                  <!-- <span class="isNew" v-if="item.withLeadNews">最新</span> -->
                  {{ item.title }}</p>
              </div>
              <div class="text">
                <!-- <span class="span2">正文：</span> -->
                <p class="p3">{{ item.summary }}</p>
              </div>
              <p class="time p2">
                — {{ item.created }}
              </p>
            </div>
            <div class="bottomStyle"></div>
          </div>
          <div class="block">
            <el-pagination @current-change="handleCurrentChange" :current-page.sync="paging.pageNumber"
              :page-size="paging.pageSize" layout="prev, pager, next, jumper" :total="paging.total">
            </el-pagination>
          </div>
        </div>
        <div class="hotNews">
          <div class="hot">
            <ul>
              <li>热门新闻</li>
              <li v-for="item in hotList.slice(0, 5)" :key="item.id" @click="toDetail(item.id)">{{ item.title }}</li>
              <!-- <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li> -->
            </ul>
          </div>
          <div class="new">
            <ul>
              <li>最新新闻</li>
              <li v-for="item in newList" @click="toDetail(item.id)" :key="item.id">{{ item.title }}</li>
              <!-- <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li>
              <li>热门新闻标题1热门新闻标题1</li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsListAPI } from '@/api/news'
import { urlencoded } from 'body-parser';
export default {
  data() {
    return {
      newsList: [],
      newList: [], // 最新新闻
      hotList: [], //热门新闻
      paging: {
        pageNumber: 1,
        pageSize: 4,
        total: 0,
      },
      isPc: true

    }
  },
  methods: {
    async getList() {
      // let time = Date.now();
      let info = {
        categoryId: Number(this.$store.state.newsTypeId),
        pageNumber: this.paging.pageNumber,
        pageSize: this.paging.pageSize,
        orderBy: "createdDesc"

      }
      const res = await newsListAPI(info);
      console.log(res);
      if (res.state === 'ok' && res.page) {
        // console.log(res.page)
        this.newsList = res.page.list
        this.paging.total = res.page.totalRow
      }
    },
    // 获取最新新闻
    async getNewList() {
      // let time = Date.now();
      let info = {
        categoryId: Number(this.$store.state.newsTypeId),
        pageNumber: 1,
        pageSize: 5,
        orderBy: "createdDesc"
        // orderBy:' desc'
      }
      const res = await newsListAPI(info);
      console.log(res);
      if (res.state === 'ok' && res.page) {
        // console.log(res.page)
        this.newList = res.page.list
      }
    },
    // 获取热门新闻
    async getHotList() {
      // let time = Date.now();
      let info = {
        categoryId: Number(this.$store.state.newsTypeId),
        pageNumber: 1,
        pageSize: 1000,
        // orderBy:'with_hot desc'
      }
      const res = await newsListAPI(info);
      console.log(res);
      if (res.state === 'ok' && res.page) {
        // console.log(res.page)
        let arr = res.page.list;
        this.hotList = [];
        if (arr.length > 0) {
          arr.forEach(item => {
            if (item.withHot === true) {
              // console.log(item)
              this.hotList.push(item);
            }
          })
        }
      }
    },
    // 分页
    handleCurrentChange(val) {
      this.paging.pageNumber = val;
      this.getList();
      // console.log(`当前页: ${val}`);
    },
    // 进入新闻详情
    toDetail(val) {
      this.$router.push({
        path: '/newsDetail',
        query: {
          id: val
        }
      })
    }
  },
  created() {
    this.isPc = sessionStorage.getItem('isPc')
    this.getList();
    this.getNewList();
    this.getHotList();
    // console.log(this.$store.state.gaugeListInfo)xx
  }
}
</script>

<style lang="scss" scoped>
.index {
  .banner {
    img {
      height: 300px;
      width: 100%;
    }
  }

  .main {
    display: block;

    .titleContent {
      margin-bottom: 22px;
    }

    .newsContent {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .newsList {
        width: 68%;
        // margin-right: 30px;

        .item {
          position: relative;
          display: flex;
          padding: 20px 0;
          border-bottom: 1px solid var(--headFont-color);

          &:hover {
            border-bottom: 1px solid white;

            .bottomStyle {
              display: block;
            }
          }

          &:first-child {
            border-top: 1px solid var(--headFont-color);
          }


          .left {
            margin-right: 30px;
            position: relative;

            .box1 {
              position: absolute;
              right: 0px;
              top: 0px;
              width: 0;
              height: 0;
              border-color: transparent transparent white transparent;
              border-style: solid;
              border-width: 0 0 135px 12px;
            }

            .box2 {
              position: absolute;
              left: 0px;
              top: 0px;
              width: 0;
              height: 0;
              border-color: white transparent transparent transparent;
              border-style: solid;
              border-width: 135px 10px 0 0;
            }

            img {
              width: 190px;
              height: 135px;
            }
          }

          .right {
            width: 100%;

            // padding-top: 30px;
            .title {

              color: var(--newsTitle-color);

              p {
                font-weight: 600;
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical;

                .isHot,
                .isNew {
                  margin-right: 5px;
                  font-size: 10px;
                  font-weight: 600;
                  color: #4B4B4B;
                  display: inline-block;
                  text-align: center;
                  line-height: 17px;
                  width: 28px;
                  height: 17px;
                  background: #D8D8D8;
                  border-radius: 2px;
                }
              }

            }

            .text {
              margin-top: 12px;
              margin-bottom: 22px;

              p {
                color: var(--memoFont-color);
                text-overflow: -o-ellipsis-lastline;
                overflow: hidden; //溢出内容隐藏
                text-overflow: ellipsis; //文本溢出部分用省略号表示
                display: -webkit-box; //特别显示模式
                -webkit-line-clamp: 2; //行数
                line-clamp: 2;
                -webkit-box-orient: vertical;
              }
            }

            .time {
              text-align: right;
              color: var(--titleFont-color);
            }

            div {
              display: flex;

              .span1,
              .span2 {
                display: inline-block;
                // width: 81px;
                font-weight: 600;
                // color: #303030;
              }

              p {
                display: inline-block;
                // width: 80%;
              }

            }


          }
        }

        .block {
          margin-top: 13px;

          .el-pagination {
            text-align: right;
          }
        }
      }

      .hotNews {
        width: 30%;

        ul {
          list-style: none;
          margin: 0;
          padding: 0;

          li {
            font-size: 15px;
            height: 48px;
            line-height: 48px;
            border: 1px solid #EEEEEE;
            border-top: none;
            padding: 0 20px;
            color: var(--memoFont-color);
            cursor: pointer;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 1; //行数
            line-clamp: 1;
            -webkit-box-orient: vertical;

            &:first-child {
              border: none;
              cursor: auto;
              background: #EEEEEE;
              font-weight: 600;
              color: var(--headFont-color);

            }
          }
        }

        .new {
          margin-top: 30px;
        }
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .index .main .newsContent {
    .hotNews {
      width: 350px;
      margin-top: 30px;
    }

    .newsList {
      width: 700px;

      .item {
        // display: block !important;
        .left{
          margin-right: 10px;
          .box1, .box2{
            display: none;
          }
          img{
            width: 150px;
            height: auto;
          }
        }
      }

    }
  }

}
</style>